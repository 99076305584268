import * as R from 'ramda';
import { supplierTypes } from 'poly-constants';

import {
  supplierBankSection,
  supplierRatesSection,
  supplierRemarksSection,
} from '../../../forms/supplierForm/index.js';
import { generalSection } from './generalSection.js';
import { settingsSection } from './settingsSection.js';
import { financialSection, renderIfPayByBranch } from './financialSection.js';
import { officeHoursSection } from '../../../forms/supplierForm/form/sections/officeHoursSection.js';
import { supplierRemitFormSection } from '../../../forms/supplierForm/form/sections/supplierRemitFormSection.js';

const renderIfRates = R.both(
  R.propEq('type', supplierTypes.SUBCONTRACTOR),
  R.propSatisfies(R.not, 'applyMasterRates'),
);

export const branchSupplierFormSections = (
  supplierUrl,
  protectedFieldsAccess,
  hasAccessToPrivateSupplier,
) => {
  const {
    hasUserAccessToUpdateBank,
    hasUserAccessToUpdateFinance,
    hasUserAccessToUpdateRemit,
  } = protectedFieldsAccess;

  return [
    ...settingsSection(hasAccessToPrivateSupplier),
    ...supplierRemarksSection,
    ...generalSection(supplierUrl),
    ...(hasUserAccessToUpdateFinance ? financialSection : []),
    ...(hasUserAccessToUpdateRemit ? supplierRemitFormSection : []),
    ...officeHoursSection,
    ...supplierRatesSection(renderIfRates),
    ...(hasUserAccessToUpdateBank
      ? supplierBankSection(renderIfPayByBranch)
      : []),
  ];
};
