import React from 'react';
import * as R from 'ramda';
import { string, shape } from 'prop-types';
import { hasOnlyUSKeyboardCharacters } from 'poly-utils';
import { Input } from 'poly-book-admin';
import {
  validateInternationalPhoneNumber,
  commonEmailValidators,
} from 'poly-client-utils/src/formValidators.js';
import {
  INVALID_SUPPLIER_NAME_MESSAGE,
  supplierEmailsTypes,
  supplierTypes,
} from 'poly-constants';
import {
  addressValidators,
  validatePhone,
  ifNotEmpty,
} from 'poly-client-utils';
import { validateURL } from 'poly-utils/src/validators.js';

import { halfWidth } from '../../../forms/common.js';
import { PhoneNumberInput } from '../../../forms/fields/index.js';
import { SupplierFormCheckbox } from '../../../forms/supplierForm/index.js';
import { WebsiteFormInput } from '../../../../components/WebsiteFormInput.js';
import { AddressSection } from '../../../forms/fields/AddressSection/index.js';
import { ToggleDBAInput } from '../../../forms/supplierForm/form/components/ToggleDBAInput.js';
import { SupplierNameInput } from '../../../forms/supplierForm/form/components/SupplierNameInput/index.js';
import { BaseServiceTypesMultiSelectField } from '../../../forms/fields/BaseServiceTypesMultiSelectField.js';
import { InternationalPhoneInput } from '../../../../components/InternationalAddressAndPhone/InternationalPhone/InternationalPhoneInput.js';
import { MultiEmailsInput } from '../../../../components/MultiEmailsInput.js';

const { ACCOUNT, SERVICE, GENERAL, SECONDARY_SERVICE } = supplierEmailsTypes;

function BranchSupplierNameInput({ formData, rootUrl, ...props }) {
  return (
    <SupplierNameInput
      skipSupplierId={formData._id}
      rootUrl={rootUrl}
      {...props}
    />
  );
}

BranchSupplierNameInput.propTypes = {
  formData: shape({ _id: string }),
  rootUrl: string.isRequired,
};

export const generalSection = (rootUrl) => [
  {
    label: 'General Information',
    order: 3,
    layout: { column: 1 },
    fields: [
      {
        label: 'Company Name',
        order: 1,
        layout: { row: 1 },
        field: {
          name: 'company.name',
          withFormData: true,
          Component: (props) => (
            <BranchSupplierNameInput
              showCharactersLeft
              rootUrl={rootUrl}
              {...props}
            />
          ),
        },
        required: true,
        validators: [
          [R.identity, 'Supplier name is required'],
          [hasOnlyUSKeyboardCharacters, INVALID_SUPPLIER_NAME_MESSAGE],
        ],
      },
      {
        label: 'Add DBA',
        order: 2,
        layout: { row: 2 },
        field: {
          name: 'company.dba',
          Component: ToggleDBAInput,
        },
      },
      {
        order: 3,
        layout: { row: 3 },
        field: {
          name: 'company.address',
          Component: AddressSection,
        },
        required: true,
        validators: addressValidators,
      },
      {
        label: 'Office Phone',
        order: 4,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'company.phone',
          Component: PhoneNumberInput,
        },
        validators: [
          [
            R.anyPass([validatePhone, R.isNil]),
            'Please provide correct phone number',
          ],
        ],
      },
      {
        label: 'Fax',
        order: 5,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'company.fax',
          Component: PhoneNumberInput,
        },
        validators: [[ifNotEmpty(validatePhone), 'Fax is not correct']],
      },
      {
        label: 'Office Email',
        order: 6,
        layout: { row: 5, width: halfWidth },
        field: {
          name: `company.emails.${GENERAL}`,
          Component: Input,
        },
        validators: commonEmailValidators,
      },
      {
        label: 'Accounting Email',
        order: 7,
        layout: { row: 5, width: halfWidth },
        field: {
          name: `company.emails.${ACCOUNT}`,
          Component: Input,
        },
        validators: commonEmailValidators,
      },
      {
        order: 8,
        layout: { row: 7, width: halfWidth },
        field: {
          withFormData: true,
          name: 'company.servicesIds',
          Component: BaseServiceTypesMultiSelectField,
        },
        validators: [
          [
            R.ifElse(R.isNil, R.F, R.complement(R.isEmpty)),
            'At least one service should be added',
          ],
        ],
      },
      {
        label: 'Primary Service Email',
        order: 9,
        layout: { row: 6, width: halfWidth },
        field: {
          name: `company.emails.${SERVICE}`,
          Component: Input,
        },
        validators: commonEmailValidators,
      },
      {
        label: 'Website',
        order: 10,
        layout: { row: 8, width: halfWidth },
        field: {
          name: 'company.website',
          Component: WebsiteFormInput,
        },
        validators: [
          [
            ifNotEmpty(validateURL),
            'URL should be in form http(s)://example.com',
          ],
        ],
      },
      {
        label: 'Service SMS Number',
        order: 11,
        layout: { row: 7, width: halfWidth },
        field: {
          name: 'company.mobile',
          Component: InternationalPhoneInput,
        },
        validators: [
          [
            R.anyPass([validateInternationalPhoneNumber, R.isNil]),
            'Please provide correct mobile phone number',
          ],
        ],
        renderIf: R.complement(R.propEq('type', supplierTypes.ADMINISTRATIVE)),
      },
      {
        label: 'Apply Master Rates',
        order: 13,
        layout: { row: 8, width: halfWidth },
        field: {
          name: 'applyMasterRates',
          Component: SupplierFormCheckbox,
        },
        renderIf: R.propEq('type', supplierTypes.SUBCONTRACTOR),
      },
      {
        label: 'Secondary Service Emails',
        order: 14,
        layout: { row: 6, width: halfWidth },
        field: {
          name: `company.emails.${SECONDARY_SERVICE}`,
          Component: MultiEmailsInput,
        },
      },
    ],
  },
];
