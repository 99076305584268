import React from 'react';
import { Select } from 'poly-book-admin';

const selectOptions = [
  { label: '>= 2%', value: { sign: 'gte', value: 2 } },
  { label: '< 2%', value: { sign: 'lt', value: 2 } },
];

export function DiscrepanciesPercentageSelect(props) {
  const selectProps = {
    ...props,
    options: selectOptions,
    placeholder: 'Select discrepancies percentage',
  };

  return <Select {...selectProps} />;
}
