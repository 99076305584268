import * as R from 'ramda';
import { undefinedAsNullDeep } from 'poly-client-utils';
import { mapObjectDeep, applySpecWithFields } from 'poly-utils';

import { prepareSupplierFormDataForMutation } from '../form/prepareFormDataForMutation.js';

// isEmptyString :: Any -> Boolean
const isEmptyString = R.both(R.is(String), R.isEmpty);

// emptyStringAsNullForSupplierInput :: Object -> Object
export const emptyStringAsNullForSupplierInput = R.compose(
  R.apply(R.mergeRight),
  R.juxt([
    // nulls are used to unset field values at backend
    mapObjectDeep(R.when(isEmptyString, R.always(null))),
    // but it should not affect remarks
    R.pick(['remarks']),
  ]),
);

// prepareEmptySupplierCompanyFields :: SupplierFormData -> SupplierFormData
export const prepareEmptySupplierCompanyFields = applySpecWithFields({
  company: {
    fax: R.pathOr('', ['company', 'fax']),
    phones: R.pathOr([], ['company', 'phones']),
    website: R.pathOr(null, ['company', 'website']),
    address: R.pathOr(null, ['company', 'address']),
  },
});

// prepareEmptySupplierRatesFields :: SupplierFormData -> SupplierFormData
export const prepareEmptySupplierRatesFields = applySpecWithFields({
  rates: {
    hourly: R.pathOr(0, ['rates', 'hourly']),
    overTime: R.pathOr(0, ['rates', 'overTime']),
    weekend: R.pathOr(0, ['rates', 'weekend']),
    trip: R.pathOr(0, ['rates', 'trip']),
  },
});

// prepareEmptySupplierField :: SupplierFormData -> SupplierFormData
// SupplierFormData = Object
export const prepareEmptySupplierField = R.compose(
  prepareEmptySupplierCompanyFields,
  prepareEmptySupplierRatesFields,
  applySpecWithFields({
    bank: {
      payByACH: R.pathOr(false, ['bank', 'payByACH']),
      routingNumber: R.pathOr('', ['bank', 'routingNumber']),
      accountNumber: R.pathOr('', ['bank', 'accountNumber']),
      remittanceEmail: R.pathOr(null, ['bank', 'remittanceEmail']),
    },
    tax: {
      name: R.pathOr(null, ['tax', 'name']),
      address: R.pathOr(null, ['tax', 'address']),
      addressTwo: R.pathOr(null, ['tax', 'addressTwo']),
    },
    foodBuy: {
      vaPercentage: R.pathOr(0, ['foodBuy', 'vaPercentage']),
      vendorType: R.pathOr(null, ['foodBuy', 'vendorType']),
      sapVendorNumber: R.pathOr(null, ['foodBuy', 'sapVendorNumber']),
      termSet: R.pathOr(null, ['foodBuy', 'termSet']),
      vaEmail: R.pathOr(null, ['foodBuy', 'vaEmail']),
    },
  }),
);

// omitRemitIfNeed :: FormData -> FormData
export const omitRemitIfNeed = R.cond([
  [
    R.both(
      R.pathSatisfies(R.isNil, ['remit', 'name']),
      R.pathSatisfies(R.isNil, ['remit', 'address', 'formatted_address']),
    ),
    R.omit(['remit']),
  ],
  [
    R.pathSatisfies(R.isNil, ['remit', 'name']),
    R.dissocPath(['remit', 'name']),
  ],
  [
    R.pathSatisfies(R.isNil, ['remit', 'address', 'formatted_address']),
    R.dissocPath(['remit', 'address']),
  ],
  [R.T, R.identity],
]);

// omitSupplierProtectedFieldsIfNoPermission :: FieldsAccess -> Supplier -> Supplier
export const omitSupplierProtectedFieldsIfNoPermission = (fieldsAccess) =>
  R.compose(
    R.unless(
      R.always(R.prop('hasUserAccessToUpdateTax', fieldsAccess)),
      R.dissoc('tax'),
    ),
    R.unless(
      R.always(R.prop('hasUserAccessToUpdateBank', fieldsAccess)),
      R.dissoc('bank'),
    ),
    R.unless(
      R.always(R.prop('hasUserAccessToUpdateFinance', fieldsAccess)),
      R.dissoc('finance'),
    ),
    R.unless(
      R.always(R.prop('hasUserAccessToUpdateRemit', fieldsAccess)),
      R.dissoc('remit'),
    ),
    R.unless(
      R.always(R.prop('hasUserAccessToUpdateDocuments', fieldsAccess)),
      R.dissoc('documents'),
    ),
  );

// prepareSupplierFinancial :: -> Supplier -> Supplier
export const prepareSupplierFinancial = R.over(
  R.lensProp('finance'),
  R.over(R.lensProp('terms'), R.defaultTo(null)),
);

// omitSupplierSubcontractorAgreement :: FormData -> FormData
export const omitSupplierSubcontractorAgreement = R.dissocPath([
  'documents',
  'subcontractorAgreement',
]);

/**
 * formSupplierDataToMutation :: FieldsAccess -> FormData -> FormData
 * FieldsAccess = {
 *  hasUserAccessToUpdateTax: Boolean,
 *  hasUserAccessToUpdateBank: Boolean
 *  hasUserAccessToUpdateFinance: Boolean,
 *  hasUserAccessToUpdateRemit: Boolean,
 *  hasUserAccessToUpdateDocuments: Boolean,
 * }
 */
export const formSupplierDataToMutation = (protectedFieldsAccess) =>
  R.compose(
    omitSupplierSubcontractorAgreement,
    omitSupplierProtectedFieldsIfNoPermission(protectedFieldsAccess),
    prepareSupplierFinancial,
    omitRemitIfNeed,
    prepareEmptySupplierField,
    prepareSupplierFormDataForMutation,
    undefinedAsNullDeep,
    emptyStringAsNullForSupplierInput,
  );
