import React from 'react';
import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { string, bool, func } from 'prop-types';
import { useNavigate, usePristineSubscribe } from 'poly-client-routing';
import { UserEmployeeInfoStatus, UserStatuses } from 'poly-constants';
import { FormPage, FormCreator } from 'poly-book-admin';
import { insertParamIntoURL } from 'poly-utils';

import {
  genders,
  rateTypes,
  maritalStatuses,
} from '../../../constants/users.js';
import {
  commonSidebarFormLayout,
  commonSidebarFormFieldLayout,
  commonSidebarFormSectionLayout,
} from '../../../sidebar/commonSidebarFormConstants.js';
import { useOnSubmitSetStopSubmitting } from '../../../hooks/useOnSubmitSetStopSubmitting.js';
import { useNotificationState } from '../../../hooks/useNotificationState.js';
import { getUserFormSections } from './sections/userFormSections.js';
import { prepareStaffUserFormDataForMutation } from './utils.js';

const CREATE_USER_WITH_GROUP_MUTATION = gql`
  mutation CREATE_USER_WITH_GROUP_MUTATION($input: CreateUserWithGroupInput!) {
    createUserWithGroup(user: $input) {
      _id
    }
  }
`;

const defaultValues = {
  general: {
    gender: genders.MALE,
    maritalStatus: maritalStatuses.MARRIED,
  },
  employeeInfo: {
    status: UserEmployeeInfoStatus.ACTIVE,
    payRateType: rateTypes.HOURLY,
  },
  systemAccess: {
    status: UserStatuses.INACTIVE,
  },
  contacts: {},
  company: {
    phones: {},
  },
};

export function AddStaffUserForm({
  url,
  formId,
  onMutationSuccess,
  isCardLayout = true,
  isContractor = false,
}) {
  const { showSuccessNotification } = useNotificationState();
  const [createUser] = useMutation(CREATE_USER_WITH_GROUP_MUTATION);
  const pristineSubscribeProps = usePristineSubscribe({
    id: formId,
    isContractor,
  });
  const navigate = useNavigate();

  const onSubmitHandler = async (formData) => {
    const input = prepareStaffUserFormDataForMutation(formData);

    const mutationResult = await createUser({ variables: { input } });

    showSuccessNotification('User was successfully created');

    const newUserId = R.path(
      ['data', 'createUserWithGroup', '_id'],
      mutationResult,
    );

    if (onMutationSuccess) {
      onMutationSuccess(newUserId);
    }

    if (url) {
      navigate(insertParamIntoURL('staffId', url, newUserId));
    }
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const formProps = {
    id: formId,
    onSubmit,
    initialValues: {
      ...defaultValues,
      ...(isContractor ? { isContractor } : {}),
    },
    sections: getUserFormSections({
      isCardLayout,
      isContractor,
    }),
    ...pristineSubscribeProps,
    ...(isCardLayout
      ? {}
      : {
          layout: commonSidebarFormLayout,
          fieldLayout: commonSidebarFormFieldLayout,
          sectionLayout: commonSidebarFormSectionLayout,
        }),
  };

  const FormComponent = isCardLayout ? FormPage : FormCreator;

  return <FormComponent {...formProps} />;
}

AddStaffUserForm.displayName = 'AddStaffUserForm';
AddStaffUserForm.propTypes = {
  url: string,
  isCardLayout: bool,
  isContractor: bool,
  onMutationSuccess: func,
  formId: string.isRequired,
};
