import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, node, oneOfType, string } from 'prop-types';

import { getThemeColor, getThemeFont } from '../utils.js';
import { ContentWrapper } from '../Tabs/components.js';
import { Heading } from '../Heading/index.js';

export const HeaderWrapper = styled.div`
  display: flex;
  padding: ${R.propOr('25px 0', 'padding')};
  height: ${R.propOr('85px', 'height')};
  justify-content: center;
  background-color: ${getThemeColor(['white'])};
  box-shadow: 0 2px 2px 0 ${getThemeColor(['midLight'])};
  z-index: 1;

  ${R.prop('headerStyles')};
`;

export function PageHeaderContainer({
  children,
  contentStyles,
  headerStyles,
  height,
  width,
  padding,
}) {
  return (
    <HeaderWrapper height={height} padding={padding} {...{ headerStyles }}>
      <ContentWrapper width={width} {...{ contentStyles }}>
        {children}
      </ContentWrapper>
    </HeaderWrapper>
  );
}

PageHeaderContainer.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  contentStyles: oneOfType([arrayOf(string), string]),
  headerStyles: oneOfType([arrayOf(string), string]),
  height: string,
  width: string,
  padding: string,
};

PageHeaderContainer.displayName = 'PageHeaderContainer';

export const MainHeader = styled(Heading.h2)`
  display: flex;
  align-items: flex-end;
  color: ${getThemeColor(['primary'])};
  font-weight: ${getThemeFont(['medium'])};
  font-size: 22px;
  line-height: 26px;
  letter-spacing: normal;
  margin-right: 30px;
  flex-shrink: 0;
`;

MainHeader.displayName = 'MainHeader';

export const MainSubHeader = styled(MainHeader)`
  color: ${getThemeColor(['primaryLighter4'])};
  font-weight: ${getThemeFont(['regular'])};
  font-size: 18px;
  line-height: 31px;
  margin: 5px 0;
`;

MainSubHeader.displayName = 'MainSubHeader';

export const HeaderLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: ${({ withoutMargin }) => (withoutMargin ? '0' : '15px')};
  align-items: center;

  > div,
  button {
    margin-left: 35px;
  }
`;

HeaderLinksWrapper.displayName = 'HeaderLinksWrapper';
