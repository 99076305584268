import { bool, func, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { UserStatuses } from 'poly-constants';
import React from 'react';
import { useOutSidebarContext } from 'poly-client-utils';

import { addClientUserFormId } from './constants.js';
import { ClientUserFormBase } from './ClientUserFormBase.js';
import { useOpenUpdateClientUser } from '../tabs/useOpenUpdateClientUser.js';
import { formatUserInput } from './form/formatUserInput.js';
import { useUserGroupListQuery } from '../../../pages/UserGroupManagement/useUserGroupListQuery.js';

const CREATE_USER_WITH_GROUP_MUTATION = gql`
  mutation CREATE_USER_WITH_GROUP_MUTATION($input: CreateUserWithGroupInput!) {
    createUserWithGroup(user: $input) {
      _id
    }
  }
`;

export function AddClientUsersForm({
  clientId,
  onCancel,
  isAssetScannerEnabled,
  isClientPortalEnabled,
}) {
  const [createUserWithGroup] = useMutation(CREATE_USER_WITH_GROUP_MUTATION);
  const openEditUserForm = useOpenUpdateClientUser(
    clientId,
    false,
    isAssetScannerEnabled,
    isClientPortalEnabled,
  );
  const { closeOutSidebar } = useOutSidebarContext();

  const { userGroupList } = useUserGroupListQuery();

  const mutate = (values) =>
    createUserWithGroup({
      variables: {
        input: formatUserInput(userGroupList)(values),
      },
    });

  const onConvertUser = (userId) => {
    closeOutSidebar(addClientUserFormId);
    openEditUserForm(userId);
  };

  return (
    <ClientUserFormBase
      clientId={clientId}
      mutate={mutate}
      formId={addClientUserFormId}
      initialValues={{
        isCreateMode: true,
        status: UserStatuses.ACTIVE,
        clientId,
        configs: {
          clientPortalApp: {
            showPMsAndAssets: true,
          },
        },
      }}
      onCancel={onCancel}
      onConvertUser={onConvertUser}
      successMessage="User successfully created"
      title="Add User"
      userGroupList={userGroupList}
      isAssetScannerEnabled={isAssetScannerEnabled}
      isClientPortalEnabled={isClientPortalEnabled}
    />
  );
}

AddClientUsersForm.propTypes = {
  clientId: string,
  onCancel: func.isRequired,
  isAssetScannerEnabled: bool,
  isClientPortalEnabled: bool,
};
