import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useReactiveQuery } from 'poly-client-utils';
import { MAX_ITEMS } from 'poly-admin-ui';

const SEARCH_PROCEDURES_NAMES_QUERY = gql`
  query SEARCH_PROCEDURES_NAMES_QUERY($input: CollectionSearchParams) {
    searchProcedures(input: $input) {
      hits {
        _id
        name
      }
    }
  }
`;

const SEARCH_PROCEDURES_SUBSCRIPTION = gql`
  subscription SEARCH_PROCEDURES_SUBSCRIPTION($input: CollectionSearchParams) {
    searchProcedureChanged(input: $input) {
      id
      type
    }
  }
`;

// prepareProceduresUsedNames :: ID -> SearchProceduresQueryResult -> [String]
const prepareProceduresUsedNames = (procedureIdToSkip) =>
  R.compose(
    R.map(R.compose(R.toLower, R.prop('name'))),
    R.when(
      R.always(procedureIdToSkip),
      R.reject(R.propEq('_id', procedureIdToSkip)),
    ),
    R.pathOr([], ['searchProcedures', 'hits']),
  );

export const useProceduresUsedNames = () => {
  const queryOptions = { variables: { input: { size: MAX_ITEMS } } };

  const { data } = useReactiveQuery(
    SEARCH_PROCEDURES_NAMES_QUERY,
    SEARCH_PROCEDURES_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  return (procedureToSkip) => prepareProceduresUsedNames(procedureToSkip)(data);
};
