import styled from 'styled-components';

import { getThemeFont } from '../utils.js';

export const Heading = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Base = styled.div.attrs({ role: 'heading' })`
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  font-weight: ${getThemeFont(['regular'])};
`;

Heading.h1 = styled(Base)`
  font-size: 28px;
`;

Heading.h2 = styled(Base)`
  font-size: 25px;
`;

Heading.h3 = styled(Base)`
  font-size: 21px;
`;

Heading.h4 = styled(Base)`
  font-size: 16px;
`;

Heading.h5 = styled(Base)`
  font-size: 14px;
`;

Heading.h6 = styled(Base)`
  font-size: 12px;
`;

Heading.displayName = 'Heading';
Heading.h1.displayName = 'Heading.h1';
Heading.h2.displayName = 'Heading.h2';
Heading.h3.displayName = 'Heading.h3';
Heading.h4.displayName = 'Heading.h4';
Heading.h5.displayName = 'Heading.h5';
Heading.h6.displayName = 'Heading.h6';
