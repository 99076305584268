import * as R from 'ramda';
import { isNilOrEmpty } from 'poly-utils';
import {
  internationalPhoneUtils,
  undefinedAsNullDeep,
} from 'poly-client-utils';
import {
  FLIPPED_CONTAINS_AIT_OPERATOR,
  ASSET_SCANNER_APP_NAME,
  CLIENT_PORTAL_APP_NAME,
  EQUALS_AIT_OPERATOR,
  PROPERTY_ID_AIT,
  CLIENT_ID_AIT,
} from 'poly-security';

// checkUserGroupUserByAppName :: (String, [UserGroup]) -> { userGroupId: ID } -> Boolean
const checkUserGroupUserByAppName = (appName, userGroupList) =>
  R.compose(
    R.propEq('defaultLoginApp', appName),
    R.find(R.__, userGroupList),
    R.propEq('_id'),
    R.prop('userGroupId'),
  );

// isKitchenUserGroupUser :: [UserGroup] -> { userGroupId: ID } -> Boolean
export const isKitchenUserGroupUser = (userGroupList) =>
  checkUserGroupUserByAppName(ASSET_SCANNER_APP_NAME, userGroupList);

// isClientPortalUserGroupUser :: [UserGroup] -> { userGroupId: ID } -> Boolean
export const isClientPortalUserGroupUser = (userGroupList) =>
  checkUserGroupUserByAppName(CLIENT_PORTAL_APP_NAME, userGroupList);

// getVariableIdByAIT :: String -> { userGroupList: [UserGroup] } -> ID
const getVariableIdByAIT = (ait) =>
  R.compose(
    R.prop('id'),
    R.find(R.propEq('type', ait)),
    R.propOr([], 'variables'),
    R.converge(R.find, [
      R.compose(R.propEq('_id', R.__), R.prop('userGroupId')),
      R.propOr([], 'userGroupList'),
    ]),
  );

// prepareUserInputVariables :: [UserGroup] -> FormValues -> [AssignUserGroupVariableInput]
export const prepareUserInputVariables = (userGroupList) =>
  R.compose(
    R.reject(R.isNil),
    R.juxt([
      R.applySpec({
        variableId: getVariableIdByAIT(CLIENT_ID_AIT),
        value: {
          [CLIENT_ID_AIT]: { [EQUALS_AIT_OPERATOR]: R.prop('clientId') },
        },
      }),
      R.ifElse(
        R.compose(isNilOrEmpty, R.prop('selectedProperties')),
        R.always(null),
        R.applySpec({
          variableId: getVariableIdByAIT(PROPERTY_ID_AIT),
          value: {
            [PROPERTY_ID_AIT]: {
              [FLIPPED_CONTAINS_AIT_OPERATOR]: R.prop('selectedProperties'),
            },
          },
        }),
      ),
    ]),
    R.mergeLeft({ userGroupList }),
  );

// formatUserInput :: (Boolean, [UserGroup]) -> FormValues -> CreateUserInput
// FomValues = Object
export const formatUserInputBase = (disableReset, userGroupList) =>
  R.converge(
    R.compose(
      R.unless(R.always(disableReset), undefinedAsNullDeep),
      R.mergeRight,
    ),
    [
      R.applySpec({
        status: R.prop('status'),
        firstName: R.prop('firstName'),
        lastName: R.prop('lastName'),
        profile: {
          cellPhoneNumber: R.prop('mobilePhone'),
          workPhoneNumber: R.prop('workPhone'),
          workPhoneNumberExt: R.prop('ext'),
        },
        userGroupId: R.prop('userGroupId'),
        variables: prepareUserInputVariables(userGroupList),
        emails: R.compose(R.reject(R.isNil), R.of, R.prop('email')),
      }),
      R.ifElse(
        isKitchenUserGroupUser(userGroupList),
        R.applySpec({
          loginCellPhoneNumber: R.compose(
            internationalPhoneUtils.formattingInternationalPhone,
            R.prop('loginCellPhoneNumber'),
          ),
        }),
        R.always({}),
      ),
    ],
  );

export const formatUserInput = (userGroupList) =>
  formatUserInputBase(false, userGroupList);
