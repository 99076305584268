import React from 'react';
import { bool } from 'prop-types';
import { BooleanTextWithDot, SectionWithCard } from 'poly-book-admin';
import { supplierAccountPropsTypes } from './supplierPropTypes.js';
import { EmailLink } from '../../components/Links.js';

export function SupplierBankACHInfoSection({
  payByACH,
  routingNumber,
  accountingNumber,
  remittanceEmail,
  hasUserBankPrivateFieldsAccess,
}) {
  return (
    <SectionWithCard
      title="Bank ACH"
      list={[
        { title: 'Pay By ACH', elem: <BooleanTextWithDot isTrue={payByACH} /> },
        ...(!payByACH || !hasUserBankPrivateFieldsAccess
          ? []
          : [
              {
                title: 'Routing Number',
                elem: routingNumber,
              },
              {
                title: 'Account Number',
                elem: accountingNumber,
              },
              {
                title: 'Remittance Email',
                elem: <EmailLink email={remittanceEmail} />,
              },
            ]),
      ]}
    />
  );
}

SupplierBankACHInfoSection.propTypes = {
  ...supplierAccountPropsTypes.bankACHPropTypes,
  hasUserBankPrivateFieldsAccess: bool.isRequired,
};
