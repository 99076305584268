import { gql } from '@apollo/client';
import { useRouterParams } from 'poly-client-routing';
import { useReactiveQuery } from 'poly-client-utils';
import { commonAddressFields } from '../../../../hocs/commonFragments.js';

const branchSupplierDetailsFields = gql`
  fragment branchSupplierDetailsFields on Supplier {
    _id
    type
    status
    applyMasterRates
    payByMaster
    isPrivate
    service_24_7
    officeHours {
      day
      isActive
      startTime
      endTime
    }
    company {
      name
      dba
      address {
        ...commonAddressFields
      }
      addressTwo
      phones {
        type
        phone
        legacyFormat
      }
      fax
      emails {
        type
        email
      }
      services {
        _id
        name
      }
      website
    }
    remarks
    finance {
      terms
      acceptsCreditCard
    }
    bank {
      routingNumber
      accountNumber
      remittanceEmail
      payByACH
    }
    rates {
      hasEmergency
      hourly
      overTime
      trip
      emergency
      weekend
    }
    remit {
      name
      address {
        ...commonAddressFields
      }
      addressTwo
    }
    tax {
      w9File {
        fileName
        url
      }
    }
  }

  ${commonAddressFields}
`;

const BRANCH_SUPPLIER_DETAILS = gql`
  query BRANCH_SUPPLIER_DETAILS($supplierId: ID!) {
    supplier(id: $supplierId) {
      ...branchSupplierDetailsFields
    }
  }

  ${branchSupplierDetailsFields}
`;

const BRANCH_SUPPLIER_DETAILS_SUB = gql`
  subscription BRANCH_SUPPLIER_DETAILS_SUB($input: SingleDocSubInput!) {
    supplierChanged(input: $input) {
      id
      document {
        ...branchSupplierDetailsFields
      }
    }
  }

  ${branchSupplierDetailsFields}
`;

export const useBranchSupplierDetailsQuery = () => {
  const { supplierId } = useRouterParams(['supplierId']);

  const queryOptions = {
    variables: { supplierId },
    fetchPolicy: 'network-only',
  };
  const subscriptionOptions = { variables: { input: { id: supplierId } } };

  const { data, loading } = useReactiveQuery(
    BRANCH_SUPPLIER_DETAILS,
    BRANCH_SUPPLIER_DETAILS_SUB,
    { queryOptions, subscriptionOptions },
  );

  return { data, loading };
};
